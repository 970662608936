<template>
  <div class="main-article" element-loading-text="正在生成页面" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="printbtn">
      <p style="color: red">注意事项：</p>
      <p>如果需要导出PDF文件，则在页面打印设置中,选择保存到PDF文件</p>
    </div>
    <div class="tc-report-main">
      <!-- <div class="content">
        <div class="report-title">
          <h1>精神科临床常用量表测验报告单</h1>
        </div>
        <div v-show="true" id="ccc">
      <div class="tc-report-main">
        <div class="content">
          <div class="report-title">
            <h1>精神科临床常用量表测验报告单</h1>
          </div>
        </div>
      </div>
    </div>
      </div> -->
      <div v-show="true" id="ccc">
        <!-- <div style="font-size:18px">{{titeltext}}</div> -->
        <div class="tc-report-main">
          <div class="content">
            <div class="report-title">
              <h1 v-if="titeltext">{{ titeltext }}</h1>

              <h1>{{ merge_department }}</h1>
            </div>
            <div class="title-top-box">
              <div class="title">一、基础信息</div>
              <!-- <div class="line"></div> -->
              <div style="border-bottom: 2px solid #000;height:3px;width:1130px;margin:20px 0px;"></div>
              <div class="title-box">
                <el-row>
                  <el-col :span="8">
                    <span class="marginRight">姓</span><span>名:</span><span class="marginLeft">{{ form.name }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="marginRight">性</span><span>别:</span><span class="marginLeft">{{ form.sex }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span class="marginRight">年</span><span>龄:</span><span class="marginLeft">{{ form.ages }}岁</span>
                  </el-col>
                </el-row>
                <el-row style="padding-top:20px">
                  <el-col :span="8">
                    <span class="marginRight">教</span><span>育:</span><span class="marginLeft">{{ form.culture }}</span>
                  </el-col>
                  <!-- <el-col :span="16">
                  <span>使用单位:</span><span  class="marginLeft">{{ config.unit_name }}</span> 
                </el-col> -->
                </el-row>
              </div>
              <div class="title">二、测验结果</div>
              <!-- <div class="line"></div> -->
              <div style="border-bottom: 2px solid #000;height:3px;width:1130px;margin:20px 0px;"></div>

              <div style="padding:0px 20px 20px 20px">
                <div style="font-size:20px">
                  <el-row style="border-bottom: 1px solid #000;height:40px;width:1018px">
                    <el-col :span="9"> <span>量表</span></el-col>
                    <el-col :span="6"> <span>项目</span></el-col>
                    <el-col :span="4"> <span>计分</span></el-col>
                    <el-col :span="4"> <span>程度</span></el-col>
                  </el-row>
                  <table>
                    <tbody align="left" v-for="(item, index) of tableInfoList" :key="index">
                      <div style="width:1018px;overflow: hidden;">
                        <tr v-for="(subitem, cindex) of item" :key="cindex" style="height:40px;line-height:40px;">
                          <template>
                            <td style="width:375px;vertical-align:middle;" :rowspan="item.length" v-if="cindex == 0">
                              <div>
                                {{ subitem.measure_title }}
                              </div>
                            </td>
                            <td style="width:258px;text-align:left;line-height:40px;vertical-align:middle;">{{
                              subitem.name
                            }}</td>
                            <td style="width:164px;text-align:left;vertical-align:middle;">{{ subitem.score }}</td>
                            <td style="text-align:left;vertical-align:middle;">{{ subitem.mark }}</td>
                          </template>
                        </tr>
                      </div>
                      <div style="border-bottom: 1px solid #000;"></div>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="title">三、临床综合分析</div>
              <!-- <div class="line"></div> -->
              <div style="border-bottom: 2px solid #000;height:3px;width:1130px;margin:20px 0px;"></div>

              <div class="footer-box">
                <div v-for="(item, index) in arrNew" :key="index" style="line-height:35px">
                  <!-- <p>{{index +1}}、 {{item.comment}}</p> -->
                  <p>♦ <span style="font-weight: bold;">{{ item.title }} :</span> {{ item.comment }}</p>
                </div>
              </div>
              <div style="display: flex;">
                <div style="flex:1">
                </div>
                <div style="width:330px;font-size:20px">
                  <div style="margin-top:22px">
                    <div>
                      <span>医生签字 ： </span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div style="border-bottom: 2px solid #000;height:3px;width:1130px;margin:20px 0px;"></div>
              <div style="display:flex;font-size:18px;justify-content: space-between;">
                <div>
                  <span>打印时间：{{ report_time }}</span>
                </div>
                <div>
                  <span>{{ disclaimer }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadReport } from "@/api/report.js";
import { getListConfig } from "@/api/comm.js";
export default {
  data() {
    return {
      // 排序
      baseArr: [],
      uid: {},
      // 表数据
      tableInfoList: [],
      itemlist: [],
      form: {},
      measure_title: "",
      arrNew: [],
      report_time: "",
      titeltext: "",
      fullscreenLoading: false,
      disclaimer: localStorage.getItem('config')['disclaimer'] || JSON.parse(localStorage.getItem('config'))['disclaimer'],// 免责声明
      merge_department: localStorage.getItem('config')['merge_department'] || JSON.parse(localStorage.getItem('config'))['merge_department'],// 合并报告标题名称
    };
  },
  created() {
    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    // console.log("全局配置",this.config.unit_name);
    getListConfig({ config_type: "system_config,user_config_scope" }).then((res) => {
      if (res.code == 400200) {
        // console.log("全局配置",res.data.unit_name);
        if (res.data.unit_name) {
          this.titeltext = res.data.unit_name;
        }
      }
    });

    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      // console.log("单");
      data.id = this.$route.query.id;
      this.uid.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      // console.log("多");
      data.id = this.$route.query.ids;
      this.uid.ids = this.$route.query.ids;
      // console.log(this.$route.query.ids.split(",").length == 1);
      if (this.$route.query.ids.split(",").length == 1) {
      }
    }
    this.loadReport(data);
    this.report_time = JSON.parse(localStorage.getItem("reportTime"))
  },
  computed: {
    removeHTMLTag() {
      return function (str) {
        // str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
        // str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
        // str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
        // str = str.replace(/ /gi, ""); //去掉
        str = str.replace(/&nbsp;/gi, "");
        return str;
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    loadReport(data) {
      loadReport(data).then((res) => {

        // 组织总评的数据
        if (res.code == 400200) {
          // 多个
          if (res.data.length > 1) {
            // 用户信息
            this.form = res.data[0].customer;
            // res的数据处理添加时间
            res.data.forEach(item => {
              if (item.base_results.comment) {
                let obj = {
                  comment: item.base_results.comment,
                  title: item.measure_title
                }
                this.arrNew.push(obj)
              }
              if (item.factor_results.length > 0) {
                item.factor_results.forEach(item2 => {
                  item2.evaluating_time = item.customer.evaluating_time.substring(0, 10)
                  item2.measure_title = item.measure_title
                })
              } else if (JSON.stringify(item.base_results) != "{}") {
                item.base_results.evaluating_time = item.customer.evaluating_time.substring(0, 10);
                item.base_results.measure_title = item.measure_title;
                item.factor_results.push(item.base_results)
              }
            })
            let dataarr = [...res.data]
            dataarr.filter(item => {
              if (item.factor_results) {
                this.tableInfoList.push(item.factor_results)
              }
            })
            // console.log("arr",this.tableInfoList);
          }
        }
        // if(res.code==400200){
        //   if(res.code==400200){

        //   // res的数据处理添加时间
        //   res.data.forEach(item=>{
        //     // console.log("每个数据",item);
        //     if(item.factor_results.length>0){
        //       item.factor_results.forEach(item2=>{
        //         item2.evaluating_time=item.customer.evaluating_time.substring(0,10)
        //       })
        //     }else if(JSON.stringify(item.base_results)!="{}"){
        //       item.base_results.evaluating_time=item.customer.evaluating_time.substring(0,10);
        //       // let obj={
        //       //     "name": "-",
        //       //     "score": "-",
        //       //     "mark": "-",
        //       //     "evaluating_time":item.customer.evaluating_time.substring(0,10)
        //       // }
        //       // item.factor_results.push(item.base_results)
        //     }
        //   })
        //   let dataarr =[...res.data]
        //   dataarr.filter(item=>{
        //     if(item.base_results.comment){
        //         this.baseArr.push(item.base_results.comment)
        //     }
        //   })
        // }
        //   let dataarr =[...res.data]
        //   dataarr.filter(item=>{
        //     if(item.base_results.comment){
        //         this.baseArr.push(item.base_results.comment)
        //     }
        //   })
        // }


        // if (Object.prototype.toString.call(res.data) == "[object Object]") {
        //   this.form = res.data.customer;
        //   if (
        //     Object.prototype.toString.call(res.data.base_results) ==
        //     "[object Object]"
        //   ) {
        //     if (
        //       Object.prototype.toString.call(res.data.factor_results) ==
        //       "[object Object]"
        //     ) {
        //       let obj = Object.assign(
        //         res.data.base_results,
        //         res.data.factor_results
        //       );
        //       obj.measure_title = res.data.measure_title;
        //       this.itemlist.push(obj);
        //     } else {
        //       let newarr = Array.from(res.data.factor_results);
        //       newarr.forEach((item) => {
        //         item.measure_title = res.data.measure_title;
        //       });
        //       let obj = Object.assign({}, res.data.base_results);
        //       obj.measure_title = res.data.measure_title;
        //       newarr.push(obj);
        //       this.itemlist.push(...newarr);
        //       debugger;
        //     }
        //   } else {
        //     if (
        //       Object.prototype.toString.call(res.data.factor_results) ==
        //       "[object Object]"
        //     ) {
        //       let newarr = Array.from(res.data.base_results);
        //       newarr.forEach((item) => {
        //         item.measure_title = res.data.measure_title;
        //       });
        //       let obj = Object.assign({}, res.data.factor_results);
        //       obj.measure_title = res.data.measure_title;
        //       newarr.push(obj);
        //       this.itemlist.push(...newarr);
        //       debugger;
        //     } else {
        //       let newarr2 = res.data.base_results.concat(
        //         res.data.factor_results
        //       );

        //       // 阳性与阴性症状量表
        //       if (res.data.measure_title == "阳性与阴性症状量表(PANSS)") {
        //         newarr2 = newarr2.slice(18, 24);
        //       }

        //       let newarr = newarr2.filter((item, index, arr) => {
        //         return (item.measure_title = res.data.measure_title);
        //       });

        //       this.itemlist.push(...newarr);
        //     }
        //   }
        // } else {
        //   this.form = res.data[0].customer;
        //   for (let i of res.data) {
        //     if (
        //       Object.prototype.toString.call(i.base_results) ==
        //       "[object Object]"
        //     ) {
        //       if (
        //         Object.prototype.toString.call(i.factor_results) ==
        //         "[object Object]"
        //       ) {
        //         let obj = Object.assign(i.base_results, i.factor_results);
        //         obj.measure_title = i.measure_title;
        //         this.itemlist.push(obj);
        //       } else {
        //         let newarr = Array.from(i.factor_results);
        //         newarr.forEach((item) => {
        //           item.measure_title = i.measure_title;
        //         });
        //         let obj = Object.assign({}, i.base_results);
        //         obj.measure_title = i.measure_title;
        //         newarr.push(obj);
        //         this.itemlist.push(...newarr);
        //         // debugger;
        //       }
        //     } else {
        //       if (
        //         Object.prototype.toString.call(i.factor_results) ==
        //         "[object Object]"
        //       ) {
        //         let newarr = Array.from(i.base_results);
        //         newarr.forEach((item) => {
        //           item.measure_title = i.measure_title;
        //         });
        //         let obj = Object.assign({}, i.factor_results);
        //         obj.measure_title = i.measure_title;
        //         newarr.push(obj);
        //         this.itemlist.push(...newarr);
        //       } else {
        //         let newarr2 = i.base_results.concat(i.factor_results);
        //         // 阳性与阴性症状量表
        //         if (i.measure_title == "阳性与阴性症状量表(PANSS)") {
        //           newarr2 = newarr2.slice(18, 24);
        //         }

        //         let newarr = newarr2.filter((item, index, arr) => {
        //           return (item.measure_title = i.measure_title);
        //         });
        //         this.itemlist.push(...newarr);
        //       }
        //     }
        //   }
        // }
        // let sorted = this.groupBy(this.itemlist, function (item) {
        //   return [item.measure_title];
        // });
        // this.tableInfoList = sorted;

        // this.tableInfoList.map(item=>{
        //   item.map(_item=>{
        //     if(_item.comment.indexOf("&nbsp") != -1){
        //         _item.comment=""
        //     }
        //       return _item
        //   })
        //   return item 
        // })

        // // 处理表格数据
        // this.tableInfoList.filter((item,index)=>{
        //   item.splice(item.length-1,1)
        //   return item
        // })
        // let arr = [];
        // for (let i of sorted) {
        //   for (let j of i) {
        //     if (j.name == "总分") {
        //       let obj = {};
        //       obj.name = j.name;
        //       obj.comment = j.comment;
        //       arr.push(obj);
        //     }
        //   }
        // }
        // this.tableInfoList2 = arr;
        // console.log(sorted, "sorted");
      });
    },
    //分组
    groupBy(array, f) {
      var groups = {};
      array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    // 打印
    fetchData() {
      setTimeout(() => {
        this.fullscreenLoading = false;
        // 视图更新完成后执行
        this.$nextTick(() => {
          window.print();
        });
      }, 1500);
    },
  },
};
</script>

<style lang="less">
@media print {
  .printbtn {
    display: none;
  }

  .PageNext {
    page-break-after: always;
  }

  .seal {
    page-break-inside: avoid;
  }

  // .page{
  //   margin: 100px 0;
  // }


  .theadtr {
    // height: 100px;
    // background-color: #000;

    th {
      border-bottom: 2px solid #000;
    }
  }

  .title-top-box {
    font-size: 20px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;

    .marginRight {
      margin-right: 44px;
    }

    .marginLeft {
      margin-left: 20px;
    }

    .title {
      margin-left: 20px;
      font-weight: bold;
      margin-top: 40px;
    }

    .line {
      height: 4px;
      background-color: #000;
      margin: 20px 0px;
    }

    .title-box {
      box-sizing: border-box;
      margin: 30px;
    }

    .footer-box {
      box-sizing: border-box;
      padding: 20px 20px 40px 20px;
    }

  }

  table {
    table-layout: fixed;
  }

  td {
    word-break: break-all;
    word-wrap: break-word;
  }
}

.printbtn {
  position: absolute;
  top: 50px;
  left: 20px;
}

.main-article {
  padding: 20px;
  display: block;
  background: #fff;
}

// 报告内容部分
.content {
  max-width: 1200px;
  margin: auto;
  background: white;
  padding: 15px;
  box-sizing: border-box;

  // 报表头部
  .report-title {
    font-size: 22px;
    text-align: center;
  }

  .title-info {
    .unit {
      font-size: 20px;
      color: #000;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }

  .user-info {
    display: flex;
    justify-content: space-between;
  }

  //   用户信息
  .userInfo {
    margin-top: 20px;

    table {
      margin: auto;
      width: 100%;
      border-top: 1px black dashed;
      border-left: 1px black dashed;

      tr {
        width: 100%;
        // font-size: 16px;
        font-weight: 700;

        td {
          padding: 5px;
          border-bottom: 1px black dashed;
          border-right: 1px black dashed;

          &:nth-child(1) {
            width: 220px;
          }

          &:nth-child(2) {
            width: 180px;
          }

          &:nth-child(3) {
            min-width: 300px;
          }

          span {
            // font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }

  //   测试结果标题.
  .test-msg {
    padding-left: 0px;
    margin-top: 10px;
    font-size: 25px;
    color: orange;
    text-shadow: black 2px 1px 1px;
    font-weight: 700;
  }

  //    图表
  .chart {
    margin-top: 10px;
    height: 350px;

    #chart-report {
      margin: 0 auto;
      //   border: black 1px solid;
    }
  }

  //   表格
  .table {
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px 0;

    // border-top: 1px black solid;
    // border-bottom: 1px black solid;
    .table-main {

      .one,
      .two {
        width: 100%;
        border-top: 1px black dashed;
        border-left: 1px black dashed;

        tr {
          width: 100%;

          th {
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
          }

          td {
            text-align: center;
            border-right: 1px black dashed;
            border-bottom: 1px black dashed;
            padding: 5px 0;
            font-size: 14px;

            &:nth-child(1) {
              // width: 180px;
            }

            &:nth-child(2) {
              // width: 150px;
            }

            &:nth-child(3) {
              min-width: 40px;
              text-align: center;
            }

            .table-icon {
              padding-left: 5px;
            }
          }
        }
      }

      .one {}

      .results-two {
        width: 100%;
        border-top: 1px dashed black;
        border-left: 1px dashed black;

        tr {
          th {
            padding: 5px 0;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;

            &:nth-child(1) {
              width: 160px;
            }

            &:nth-child(2) {
              width: 180px;
            }

            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }

            &:nth-child(5) {
              width: 180px;
            }

            &:nth-child(6) {
              width: 50px;
            }
          }

          td {
            box-sizing: border-box;
            padding: 2px 5px 2px 5px;
            vertical-align: middle;
            border-bottom: 1px dashed black;
            border-right: 1px dashed black;
            text-align: center;

            &:nth-child(1) {
              width: 160px;
            }

            &:nth-child(2) {
              width: 180px;
            }

            &:nth-child(3) {
              width: 50px;
            }

            &:nth-child(4) {
              width: 160px;
            }

            &:nth-child(5) {
              width: 180px;
            }

            &:nth-child(6) {
              width: 50px;
            }
          }
        }
      }
    }
  }

  .table2 {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 20px;
  }

  //   健康状态
  .condition {
    margin-top: 10px;
    border-bottom: 1px black solid;
    padding-bottom: 14px;

    table {
      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }

      .condition-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }

  //   每项分析
  .analyze {
    margin-top: 20px;
    padding-bottom: 10px;

    table {
      padding: 10px 0;
      border-bottom: 1px black solid;
      width: 100%;

      tr {
        td {
          text-indent: 2em;
          letter-spacing: 3px;
          line-height: 25px;
          // font-size: 15px;
        }
      }

      .analyze-title {
        text-indent: 0;
        padding-top: 10px;
        // font-size: 16px;
        font-weight: 700;
      }
    }
  }

  // 签名
  .signature {
    position: relative;
    text-align: right;
    margin-top: 40px;

    .title {
      font-weight: 700;

      .signature_img {
        display: inline-block;
        min-width: 100px;
      }
    }

    .data {
      margin-left: 20px;
    }

    img {
      // position: absolute;
      // top: -40px;
      // left: 284px;
    }
  }
}

// 明尼苏达
.minnesota {
  transform: translateX(-55px);

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  .remind {
    border-top: 1px black solid;
    border-bottom: 1px black solid;
    padding: 5px 0;
  }

  .el-divider--horizontal {
    margin: 5px 0;
  }

  .el-divider {
    background-color: rgb(133, 133, 133);
  }

  .mmpi {
    margin-top: 40px;

    .mmpi_table {
      margin-top: 20px;

      table:nth-child(1) {
        width: 100%;
        font-size: 14px;

        tr:nth-child(1) {
          display: flex;

          td {
            flex: 1;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }

            span {
              display: inline-block;
              // text-align: center;
              height: 120px;
              writing-mode: vertical-lr;
              letter-spacing: 8px;
            }
          }
        }

        tr:nth-child(2) {
          display: flex;

          td {
            flex: 1;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }

            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }

      table:nth-child(3) {
        width: 100%;
        font-size: 14px;

        tr {
          display: flex;

          td {
            flex: 1;
            display: flex;
            justify-content: center;

            &:nth-child(1) {
              flex: 4;
              text-align: left;
              justify-content: flex-start;
            }

            span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }

      .mmpi_summarize {
        padding: 10px 0;
      }
    }
  }

  .t {
    margin-top: 40px;

    .t_table {
      margin-top: 20px;

      >table:nth-child(1) {
        width: 100%;
        font-size: 14px;

        tr {
          display: flex;

          >td {
            flex: 1;
            display: flex;
            justify-content: center;

            >span {
              display: inline-block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }

      >table:nth-child(2) {
        width: 100%;
        font-size: 14px;
        border: black 1px solid;
        border-collapse: collapse;

        tr {
          &:nth-child(1) {
            td:nth-child(4) {

              // display: flex;
              span {
                display: inline-block;
                width: 9%;
                justify-content: space-around;
                position: relative;
                text-align: center;

                &::after {
                  font-size: 17px;
                  content: "|";
                  position: absolute;
                  bottom: -16px;
                  right: 9px;
                  z-index: 999;
                }

                &::before {
                  font-size: 10px;
                  content: "|||||||||";
                  position: absolute;
                  bottom: -11px;
                  right: -3px;
                  z-index: 999;
                }
              }
            }
          }

          td {
            text-align: center;
            border-right: black 1px solid;
            border-bottom: black 1px solid;

            &:nth-child(1) {
              width: 100px;
            }

            &:nth-child(2) {
              width: 50px;
            }

            &:nth-child(3) {
              width: 180px;
            }

            &:nth-child(4) {
              position: relative;
              text-align: left;
              font-size: 10px;
            }

            &:nth-child(5) {
              width: 150px;
            }
          }
        }

        .cursor {
          color: black;
          display: inline-block;
          position: absolute;

          .el-icon-caret-top {
            font-size: 20px;
          }
        }
      }

      .forecast {
        margin-top: 30px;

        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 5px 5px;

          caption {
            font-size: 16px;
            font-weight: 700;
            padding: 10px 0;
          }

          tr {
            &:nth-child(2) {
              font-weight: 700;

              td {
                &:nth-child(1) {
                  width: 120px;
                }
              }
            }

            td {
              width: 50px;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .conclusion {
    margin-top: 40px;

    table {
      width: 100%;
      border-collapse: collapse;

      caption {
        padding: 5px 0;
        font-weight: 700;
        font-size: 18px;
      }

      tr {
        th {
          border-top: 1px black solid;
          border-bottom: 1px black solid;
          padding: 5px 0;

          &:nth-child(1) {
            width: 200px;
            text-align: center;
          }

          &:nth-child(2) {
            width: 150px;
            text-align: center;
          }

          &:nth-child(3) {}
        }

        td {
          font-size: 14px;
          padding: 5px 0;

          &:nth-child(1) {}

          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }

  .auxiliary {
    margin-top: 40px;

    .auxiliary_title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }

    .auxiliary_main {
      .auxiliary_item {
        padding: 10px 0;

        .item_title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }

  .describe {
    margin-top: 40px;

    .describe_main {
      .describe_content_title {
        font-size: 16px;
        text-align: center;

        p {
          margin: 30px 0px 9px 0px;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .describe_content {
        font-size: 14px;
        padding: 10px 0;
      }
    }
  }

  .zDescribe {
    margin-top: 60px;

    .zDescribe_title {
      font-weight: 700;
      text-align: center;
    }

    .zDescribe_content {
      margin-top: 40px;
      font-size: 14px;
    }

    .zDescribe_msg {
      margin-top: 50px;

      p {
        font-weight: 700;

        span {
          font-weight: 100;
        }
      }
    }
  }

  .suggest {
    border-top: 1px black solid;
    margin-bottom: 5px;
    padding: 5px 0;

    .suggest_msg {
      font-weight: 700;
    }

    .suggest_content {
      height: 200px;
    }
  }
}
</style>
<style scoped lang="less">
.theadtr {
  // height: 100px;
  // background-color: #000;

  th {
    border-bottom: 2px solid #000;
  }
}

.title-top-box {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;

  .marginRight {
    margin-right: 44px;
  }

  .marginLeft {
    margin-left: 20px;
  }

  .title {
    margin-left: 20px;
    font-weight: bold;
    margin-top: 40px;
  }

  .line {
    height: 4px;
    background-color: #000;
    margin: 20px 0px;
  }

  .title-box {
    box-sizing: border-box;
    margin: 30px;
  }

  .footer-box {
    box-sizing: border-box;
    padding: 20px 20px 40px 20px;
  }

  table {
    table-layout: fixed;
  }

  td {
    word-break: break-all;
    word-wrap: break-word;
  }
}
</style>
